.contact__container {
  grid-template-columns: 4fr 8fr;
}

.contact__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact__description {
  font-size: 15px;
  line-height: 1.6;
}

.contact__description,
.contact__info .info__item {
  margin-bottom: 20px;
}

.contact__info .info__item {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.contact__info .info__icon {
  color: hsla(11, 95%, 57%, 0.977);
  font-size: 32px;
}

.contact__info .info__title {
  font-size: 15px;
}

.contact__info .info__desc {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.contact__socials {
  display: flex;
  column-gap: 16px;
}

.contact__social-link {
  background-color: hsl(0, 0%, 93%);
  color: hsl(0, 0%, 40%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 44px;
  transition: all 0.3s ease;
}

.contact__social-link:hover {
  background-color: hsla(11, 95%, 57%, 0.977);
  color: #fff;
}

.form__input-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
}

.form__input-div {
  margin-bottom: 30px;
}

.form__control {
  border: 1px solid hsl(0, 0%, 93%);
  background-color: hsl(0, 0%, 93%);
  color: hsl(0, 0%, 40%);
  width: 100%;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 15px;
  transition: all 0.3s ease;
}

.form__control:focus {
  border-color: hsla(11, 95%, 57%, 0.977);
}

.textarea {
  resize: none;
  height: 160px;
}

.contact__button-icon {
  font-size: 24px;
  line-height: 64px;
}
